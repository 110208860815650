/* const ComponentFontAdjustments = {
  [ComponentGroups.WYSIWIG]: {
    h1: '42px',
    h2: '42px',
    h3: '26px',
    h4: '26px',
    h5: '26px',
    h6: '26px',
    'content-large-breakpoint': '18px',
    content: '16px',
    button: '16px',
  },
  [ComponentGroups.HERO]: {
    title: '100px',
    'title-large-bp': '100px',
    'title-smaller-bp': '68px',
    copy: '35px',
    'copy-larger-bp': '40px',
    'copy-large-bp': '35px',
    'copy-smaller-bp': '28px',
  },
}; */

import {
  FontSize,
  FontFamily,
  TextTransformOptions,
  Components,
} from '@powdr/constants';

const fonts = {
  [FontFamily.BASE_FONT]: {
    fontFamily: '"LFT Etica Sheriff"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
  [FontFamily.PRIMARY_FONT]: {
    fontFamily: '"Oswald-Bold"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.LRG30,
    scaling: 1,
  },
  [FontFamily.SECONDARY_FONT]: {
    fontFamily: '"Oswald"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
};

const fontOverrides = {};

export const fontTheme = {
  fonts,
  FontSize,
  fontOverrides,
};
